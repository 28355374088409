import React from 'react'
import Layout from '../components/Layout'
import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({
}) => (
  <div>
    {/* <h1>{title}</h1>
    <h2>{description}</h2> */}
    <section className="section section--gradient">
      <BlogRoll />
    </section>
  </div>
)



const IndexPage = () => {

  return (
    <Layout>
      <IndexPageTemplate />
    </Layout>
  )
}

export default IndexPage
